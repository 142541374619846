@import '../../sassStyles/variables';

.custom-button {
    min-width: 160px;
    width: auto;
    height: 50px;
    letter-spacing: 1px;
    padding: 0 35px;
    margin: 5px;
    font-size: 12px;
    background-color: $white-01;
    border: 2px solid $blue-dark;
    color: #3F3F3E;
    text-transform: uppercase;
    font-family: 'Open Sans Condensed';
    font-weight: bolder;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
  
    &:hover {
      background-color: $blue-dark;
      color: $white-01;
    }

    &.google-sign-in {
      background-color: #4285F4;
      color: white;
      border: none;

      &:hover {
        background-color: #357AE8;
        border: none;
      }
    }
  }