

/* color */
$blue-light: #CADDF0;
$blue-regular: #8AAFD2;
$blue-dark: #375FAA;

$gray-light: #59616A;
$gray-dark: #0F1D3A;
$gray-invalid-01: #6a7b9e;
$gray-invalid-02: #526071;

$white-01: #FFFFFF;
$white-02: #FDFDFD;
$white-03: #FEFEFE;

$red-error: #B20C0C;

/* fonts */
$spacing: 3px;

/* width */
$max-width: 1280px;

/* height */
$nav-height: 72px;
$main-height: calc(100vh - 72px);

$radius: 5px;