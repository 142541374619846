@import '../../sassStyles/variables';
@import '../../sassStyles/mixins';

.contact {
  
  &__container {
    max-width: 1280px;
    height: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(12,1fr);

    @include for-laptop {
      max-width: 1580px;
      grid-template-rows: 250px auto;
    }
  }

  &-form {
    width: 100%;
    height: 764px;
    position: relative;
    top: 96px;
    grid-row: 1/1;
    grid-column: 3/span 9;
    background-color: $white-02;
    //border: 0.5px solid $blue-light;
    box-sizing: border-box;
    box-shadow: 5px 8px 50px rgba(25, 87, 164, 0.2);
    border-radius: 20px;

    @include for-laptop {
      grid-row: 2/-1;
      grid-column: 2/span 10;
    }

    &__container {
      width: 640px;
      position: relative;
      top: 0;
      left: 270px;

      @include for-laptop {
        left: 0;
        margin: 0 auto;
      }

      @include for-tablet-landscape {
        width: 400px;
      }

      @include for-phone {
        width: 300px;
      }

      h2 {
        margin-top: 56px;
      }

      .error {
        position: relative;
        top: 30px;
        right: 0;
        display: block;
        font-size: 12px;
        color: $red-error;
        text-align: right;
      }

      .errorArea {
        position: relative;
        top: 30px;
        right: 0;
        display: block;
        font-size: 12px;
        color: $red-error;
        text-align: right;
      }
    }
  }

  &-info {
    width: 366px;
    height: 454px;
    position: relative;
    top: 250px;
    grid-row: 1/1;
    grid-column: 1/span 4;
    background-color: $blue-regular;
    border-radius: 15px;
    filter: drop-shadow(5px 5px 20px rgba(0, 0, 0, 0.25));
    justify-self: end;
    
    @include for-laptop {
      width: 90%;
      height: 100%;
      top: 0;
      grid-row: 1/1;
      grid-column: 1/span 12;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      margin: 0 auto;
    }

    @include for-tablet-landscape {
      flex-direction: column;
    }
  }
}

