@import '../../sassStyles/variables';
@import '../../sassStyles//mixins';

.banner {
  width: 100%;
  height: 800px;
  position: relative;
  margin-bottom: 150px;

  @include for-tablet-landscape {
    height: 500px;
  }

  img {
    width: 100%;
    height: 900px;
    object-fit: cover;
    object-position: center 20%;

    @include for-tablet-landscape {
      height: 500px;
    }
  }

  .img-mask {
    width: 100%;
    height: 900px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(24, 22, 22, 0.2);

    @include for-tablet-landscape {
      height: 500px;
    }
  }

  .value {
    width: 350px;
    position: absolute;
    top: 250px;
    left: 100px;
    color: $white-01;
    display: flex;
    flex-direction: column;

    @include for-tablet-landscape {
      width: 335px;
      top: 100px;
      left: 100px;
    }

    @include for-tablet-portrait {
      width: 200px;
      left: 50px;
    }

    p {
      font-family: "SFProText-Semibold";
      font-size: 70px;
      margin-bottom: 32px;

      @include for-tablet-landscape {
        font-size: 54px;
        margin-bottom: 24px;
      }

      @include for-tablet-portrait {
        font-size: 48px;
        margin-bottom: 20px;
      }
    }

    span {
      font-family: "SFProText-Light";
      font-size: 16px;
      margin-bottom: 12px;
      padding-left: 60px;

      @include for-tablet-landscape {
        font-size: 14px;
        margin-bottom: 8px;
      }

      @include for-tablet-landscape {
        font-size: 12px;
        margin-bottom: 6px;
        padding-left: 48px;
      }
    }
  }

  .arrow {
    width: 100%;
    position: absolute;
    top: calc(#{$nav-height} + 630px);
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @include for-tablet-landscape {
      top: calc(#{$nav-height} + 330px);
    }

    p {
      position: absolute;
      top: 50px;
      font-weight: 100;
      color: $white-01;
    }

    &__container {
      width: 1px;
      height: 60px;
      position: relative;
      margin-bottom: 100px;
    }
  }
  
  .line {
    position: absolute;
    width: 1px;
    height: 0px;
    background-color: $white-01;
    top: 0;
    bottom: initial;
    animation: line-animation 1.5s ease-in-out 0s infinite normal; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
      content: ' ';
      width: 8px;
      height: 8px;
      border-right: 1px solid $white-01;
      border-bottom: 1px solid $white-01;
      transform: rotate(45deg);
      display: block;
      position: absolute;
      bottom: 1px;
      animation: arrow-animation 1.5s ease-in-out 0s infinite normal; 
    }
  }
  
  @keyframes line-animation {
    0% {height: 0px; top: 0px; opacity: 0;}
    50% {height: 44px; top: 0px; opacity: 1;}
    100% {height: 0px; top: 44px; opacity: 0;}
  }
  
  @keyframes arrow-animation {
    0% {opacity: 0}
    50% {opacity: 1}
    100% {opacity: 0}
  }  
}