@import '../../sassStyles/variables';
@import '../../sassStyles/mixins';

.info {
  width: 290px;
  position: relative;
  top: 0;
  left: 42px;
  margin-top: 80px;
  padding: 0;
  display: flex;
  flex-direction: row;

  @include for-laptop {
    left: 0;
    margin-top: 0;
    min-height: 32px;
  }

  @include for-tablet-landscape {
    min-height: 24px;
    height: 24px;
    top: -16px;
    margin-top: 16px;
  }

  .svg {
    width: 32px;
    height: 32px;

    @include for-tablet-landscape {
      width: 14px;
      height: 14px;
    }
  }

  span {
    margin-top: 5px;
    margin-left: 16px;
    transition: 0.5s;
    
    /*&:hover {
      color: #0a2f75;
      text-decoration: underline;
      transition: 0.5s;
    }*/

    @include for-tablet-landscape {
      font-size: 12px;
    }
  }
}