@import '../../sassStyles/variables';
@import '../../sassStyles//mixins';

section {
  width: 100%;
  margin-bottom: 180px;

  @include for-phone {
    margin-bottom: 150px;
  }

  .account_btn {
    width: 200px;
    margin-top: 48px;
    background-color: $blue-dark;
    color: $white-01;
    cursor: pointer;
    font-family: "SFProText-Light";
    font-size: 20px;

    &:hover {
      @include buttonHover;
    }
  }
}