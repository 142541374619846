@import '../../sassStyles/variables';
@import '../../sassStyles//mixins';

.order-flow {
  width: 100%;
  height: 636px;
  position: relative;
  margin: 0 auto;

  @include for-tablet-landscape {
    height: 1250px;
  }

  @include for-tablet-portrait {
    height: 2274px;
  }

  h2 {
    text-align: center;
    margin-bottom: 84px;
  }

  &__container {
    max-width: 1280px;
    height: 400px;
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;

    @include for-laptop {
      width: 996px;
      min-width: 996px;
    }

    @include for-tablet-landscape {
      width: 688px;
      min-width: 688px;
      height: 864px;
    }

    @include for-tablet-portrait {
      width: 350px;
      min-width: 350px;
      height: 1320px;
    }
  }
}