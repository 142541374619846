@import '../../sassStyles/variables';
@import '../../sassStyles/mixins';

.card {
  width: 280px;
  height: 400px;
  position: relative;
  top: -60px;
  left: 0;
  text-align: center;
  margin: 0 20px;
  border-radius: 15px;
  background-color: rgb(244, 249, 255);
  transition: 0.3s ease-in;

  @include for-laptop {
    width: 230px;
    margin: 0 9px;
  }

  @include for-tablet-landscape {
    min-width: 280px;
    margin: 0 32px 32px 32px;
  }

  @include for-tablet-portrait {
    min-width: 300px;
    margin: 0;
  }

  span {
    position: absolute;
    top: 8px;
    left: 24px;
    font-size: 48px;
    font-family: moret, serif;
    font-weight: 800;
    font-style: italic;
    color: #5472aab0;
  }

  h4 {
    position: relative;
    top: 80px;
  }

  &__icon {
    width: 120px;
    height: 120px;
    position: relative;
    top: 80px;
    margin: 0 auto;
    margin-top: 80px;
    margin-bottom: 48px;

    @include for-tablet-portrait {
      width: 100px;
      height: 100px;
    }

    img {
      width: 100%;
    }
  }

  &__desc {
    width: 220px;
    max-height: 50px;
    position: relative;
    top: 80px;
    margin: 0 auto;
    margin-top: 30px;
    text-align: center;
  }

  .request {
    width: 140px;
  }

  &:hover {
    background-color: #88adcf7a;
    transform: translateY(-24px);
    transition: 0.3s ease-in;
    box-sizing: border-box;
    box-shadow: 3px 5px 15px rgba(20, 79, 150, 0.4);
  }
}