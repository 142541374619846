@import '../../sassStyles/variables';
@import '../../sassStyles/mixins';

.about {
  width: 100%;
  position: relative;
  top: $nav-height;

  .intro__container {
    width: 100%;
    height: 600px;
    position: relative;
    background: rgb(202,221,240) linear-gradient(180deg, rgba(202,221,240,1) 28%, rgba(138,175,210,1) 85%);
    text-align: start;
    overflow: hidden;

    span {
      font-size: 40px;
      font-family: "SFProText-Regular";
      position: relative;
      top: 100px;
      left: 12%;
      margin: 0;
      background-color: $blue-dark;
      color: $white-01;
      padding: 8px 24px;

      @include for-tablet-landscape {
        left: 80px;
      }
    }

    h1 {
      @include pageTitle;
    }

    .introduction {
      width: 400px;
      position: relative;
      top: 66px;
      left: 12%;
      text-align: start;
      z-index: 2;

      @include for-tablet-landscape {
        left: 80px;
      }

      p {
        text-align: start;
      }
    }

    .icon {
      width: 60%;
      height: 60%;
      position: absolute;
      top: 230px;
      right: -10%;
      opacity: 0.5;
      transform: rotate(30deg);
      overflow: hidden;

      @include for-tablet-portrait {
        visibility: hidden;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  

  .timeline{
    &__section {
      width: 100%;
      position: relative;
      top: 150px;

      @include for-tablet-landscape {
        top: 100px;
      }

      h2 {
        margin-top: 0;
        margin-bottom: 60px;
        text-align: center;

        @include for-tablet-landscape {
          font-size: 36px;
        }
      }

      .timeline__wrapper {
        width: 85%;
        height: 420px;
        position: relative;
        margin: 0 auto;

        @include for-tablet-landscape {
          width: 100%;
          height: 900px;
          padding: 0 100px;
        }

        @include for-tablet-portrait {
          height: 1300px;
        }
  
        ol {
          border-top: 1px solid $blue-regular;
          list-style: none;
          display: flex;
          flex-direction: row;

          @include for-tablet-landscape {
            flex-direction: column;
            border: none;
            border-left: 1px solid $blue-regular;
          }

          &::after {
            position: absolute;
            right: 0;
            width: 50px;
            height: 50px;
            border: 2px solid $blue-regular;
            display: block;
          }
  
          .timeline {
            width: 185px;
            height: 200px;
            position: relative;
            top: 0;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            padding: 0 24px;

            @include for-tablet-landscape {
              width: 600px;
              min-height: 200px;
              flex-direction: column;
              margin: 0;
              padding: 24px 0;
              left: 0;
            }

            @include for-tablet-portrait {
              width: 80%;
              min-height: 250px;
            }
  
            &::before {
              content: '';
              width: 10px;
              height: 10px;
              border-radius: 100%;
              border: 2px solid $blue-dark;
              background-color: $white-01;
              color: $blue-dark;
              position: absolute;
              top: -8px;
              left: -8px;
              display: block;

              @include for-tablet-landscape {
                top: -8px;
                left: -8pxpx;
              }
            }

            &::after {
              content: '';
              width: 8px;
              height: 8px;
              border-radius: 100%;
              background-color: $blue-dark;
              color: $blue-dark;
              position: absolute;
              top: 60px;
              left: -5px;
              display: block;

              @include for-tablet-landscape {
                width: 0;
                height: 0;
              }
            }

            span {
              font-family: "SFProText-Semibold";
              font-size: 24px;
              color: $blue-dark;
              position: relative;
              top: 25px;
              left: -9px;
              margin-top: 20px;
              text-align: start;

              @include for-tablet-landscape {
                top: -65px;
                left: 20px;
              }

              &::before {
                content: '';
                width: 2px;
                height: 65px;
                background-color: $blue-dark;
                display: block;
                position: absolute;
                top: -45px;
                left: -17px;
                z-index: -1;

                @include for-tablet-landscape {
                  width: 0;
                  height: 0;
                }
              }
            }

            p {
              position: relative;
              top: 40px;
              left: -9px;
              text-align: start;

              @include for-tablet-landscape {
                top: -50px;
                left: 30px;
              }
            }
          }
        }
      }
    }

  }
}
