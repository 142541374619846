@import '../../sassStyles/variables';
@import '../../sassStyles/mixins';

.input-box {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    position: relative;
    font-size: 20px;
    margin-bottom: 8.5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include for-tablet-portrait {
      font-size: 16px;
    }

    p {
      position: relative;
      top: 20px;
      font-size: 12px;
      color: $red-error;
      visibility: hidden;
    }
  }

  input {
    width: 636px;
    height: 60px;
    border: 1px solid $blue-regular;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px 16px;
    margin-bottom: 24px;
    font-size: 18px;
    font-family: "SFProText-Thin";

    @include for-tablet-landscape {
      width: 400px;
    }

    @include for-tablet-portrait {
      height: 48px;
    }

    @include for-phone {
      width: 300px;
      font-size: 14px;
    }

    &::placeholder {
      font-size: 18px;
      font-family: "SFProText-Thin";
      color: $blue-regular;

      @include for-tablet-portrait {
        font-size: 14px;
      }
    }
    
  }
 
}

.input-box.error-input label p {
  visibility: visible;
}

.error-input input {
  border: 1px solid $red-error;
}

.success-input input {
  background-color: $blue-light;
}