@import '../../sassStyles/variables';
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@1&display=swap');
@import '../../sassStyles//mixins';

.title {
  position: relative;
  margin: 0 0 88px 0;

  span {
    position: absolute;
    top: -80px;
    left: 0;
    font-family: 'DM Serif Display', serif;
    font-size: 140px;
    color: $blue-dark;
    opacity: 0.2;

    @include for-tablet-portrait  {
      top: -50px;
      font-size: 90px;
    }
  }

  h3 {
    width: 350px;
    color: $blue-dark;
    position: relative;
    top: 50px;
    left: 160px;

    @include for-tablet-portrait  {
      width: 300px;
      font-size: 24px;
      top: 60px;
      left: 0;
    }
  }
}