@mixin for-phone {
  @media (max-width: 500px) { @content; }
}
@mixin for-tablet-portrait {
  @media (max-width: 768px) { @content; }
}
@mixin for-tablet-landscape {
  @media (max-width: 992px) { @content; }
}
@mixin for-small-laptop {
  @media (max-width: 1080px) { @content; }
}
@mixin for-laptop {
  @media (max-width: 1300px) { @content; }
}

@mixin boxShaddow {
  box-sizing: border-box;
  box-shadow: 3px 5px 20px rgba(27, 96, 182, 0.4);
}

@mixin buttonHover {
  background-color: #2D5091;
  transform: translateY(-5px);
  transition: 0.3s;
  @include boxShaddow;
}

@mixin pageTitle {
  font-size: 32px;
  color: #325BA6;
  text-align: start;
  position: relative;
  top: 80px;
  padding-left: 12%;

  @include for-tablet-landscape {
    padding-left: 80px;
  }
}