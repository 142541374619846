@import '../../sassStyles/variables';
@import '../../sassStyles/mixins';

header {
  width: 100%;
  height: $nav-height;
  position: fixed;
  top: 0;
  background: $white-02;
  box-shadow: 0px 1px 20px rgba(55, 95, 170, 0.25);
  border-radius: 2px;
  z-index: 10;
}

.header__container {
  //max-width: $max-width;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  margin: 0 auto;
  display: grid;
  grid-row: 1/1;
  grid-template-columns: 2fr 1fr 100px;
  z-index: 20;

  @include for-tablet-landscape {
    grid-template-columns: 1fr 1fr 50px;
  }

  .uselec {
    width: 120px;
    position: fixed;
    top: 16px;
    left: 100px;

    @include for-tablet-portrait {
      left: 50px;
    }

    h3 {
      grid-column-start: 1;
      grid-column-end: 2;
      margin: 0;
      color: $blue-dark;

      @include for-tablet-portrait {
        left: 50px;
      }
    }
  }
}

.menuBtn { 
  display: none;

  @include for-tablet-portrait {
    display: inline;
  }
}

.menu {
  height: 100%;
  grid-column-start: 2;
  grid-column-end: 3;
  //padding-right: 32px;

  @include for-tablet-portrait {
    display: none;
  }

  &__links {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include for-tablet-portrait {
      display: none;
    }
  }

  &__link {
    font-size: 16px;

    @include for-tablet-landscape {
      padding-left: 32px;
    }

    .option:hover {
      color: $blue-dark;
    }
  }
}
