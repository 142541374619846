@import 'variables';

h1,
h2,
h3,
h4 {
  letter-spacing: $spacing;
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 16px;
  color: $blue-dark;
}

h1 {
  font-family: "SFProText-Medium";
  font-size: 64px;
  margin: 96px 0 36px 0;
  text-align: center;
}

h2 {
  font-family: "SFProText-Medium";
  font-size: 48px;
  margin: 96px 0 36px 0;
}

h3 {
  font-family: "SFProText-Regular";
  font-size: 32px;
}

h4 {
  font-family: "SFProText-Semibold";
  font-size: 28px;
}

p {
  font-family: "SFProText-Light";
  font-size: 14px;
}