@import '../../sassStyles/variables';
@import '../../sassStyles//mixins';

.orderflow {
  width: 100%;
  position: relative;
  top: $nav-height;
  margin: 0;

  .intro__container {
    width: 100%;
    height: 600px;
    position: relative;
    background: rgb(202,221,240) linear-gradient(180deg, rgba(202,221,240,1) 28%, rgba(138,175,210,1) 85%);
    text-align: start;
    overflow: hidden;

    span {
      font-size: 40px;
      font-family: "SFProText-Regular";
      position: relative;
      top: 100px;
      left: 12%;
      margin: 0;
      background-color: $blue-dark;
      color: $white-01;
      padding: 8px 24px;

      @include for-tablet-landscape {
        left: 80px;
      }
    }

    h1 {
      @include pageTitle;
    }

    .introduction {
      width: 300px;
      position: relative;
      top: 66px;
      left: 12%;
      text-align: start;
      z-index: 2;

      @include for-tablet-landscape {
        left: 80px;
      }

      p {
        text-align: start;
      }
    }

    .icon {
      width: 50%;
      height: 50%;
      position: absolute;
      top: 230px;
      right: -10%;
      opacity: 0.5;
      transform: rotate(-10deg);
      overflow: hidden;

      @include for-tablet-portrait {
        visibility: hidden;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__container {
    width: 100%;
    height: 2700px;
    position: relative;
    padding-top: 100px;
    display: grid;
    grid-template-columns: repeat(12,1fr);

    .steps {
      position: relative;
      top: 5%;
      left: 15%;
      grid-column: 2/span 2;

      @include for-small-laptop {
        left: 8%;
      }

      @include for-tablet-landscape {
        left: 5%;
      }

      @include for-tablet-portrait  {
        grid-column: 1/span 3;
        left: 8px;
      }

      &__container {
        width: 100%;
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        display: flex;
        flex-direction: row;

        .indicator {
          width: 1px;
          height: 374px;
          position: relative;
          left: 24px;

          @include for-tablet-portrait  {
            height: 255px;
            padding-bottom: 20px;
          }

          &::before {
            z-index: 1;
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $blue-dark;
            opacity: 0.3;
          }
        }

        ul {
          position: relative;
          left: 40px;

          li {
            position: relative;
            top: -10px;
            padding-bottom: 60px;
            cursor: pointer;

            @include for-tablet-portrait  {
              padding-bottom: 20px;
            }

            &::before {
              content: "";
              width: 5px;
              height: 5px;
              display: block;
              position: relative;
              top: 10px;
              left: -19px;
              border-radius: 50%;
              background-color: $blue-dark;
            }

            .step {
              @include for-tablet-portrait  {
                font-size: 12px;
              }
            }

            .step::after {
              content: "";
              display: block;
              width: 36px;
              height: 36px;
              position: relative;
              left: -10px;
              top: -30px;
              border-radius: 50%;
              border: 1px solid $blue-dark;
              opacity: 0;
              transform: rotateY(90deg);
              transition-property: opacity,transform;
              transition-duration: 0.2s;
            }

            .step.active::after {
              opacity: 1;
              transform: rotateY(0deg);
            }
          }
        }
      }
    }

    /*.indicator__progress {
      z-index: 2;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 35%;
      background-color: #93a6cb;
      z-index: -1;
    }*/

    .flow {
      height: 1200px;
      grid-column: 6/span 5;

      @include for-small-laptop {
        grid-column: 5/span 6;
      }

      @include for-tablet-landscape {
        height: 550px;
        grid-column: 5/span 7;
      }

      @include for-tablet-portrait  {
        grid-column: 6/span 6;
      }
    }
  }
}
