@import '../../sassStyles/variables';
@import '../../sassStyles/mixins';

button {
  width: 224px;
  height: 62px;
  background-color: $gray-invalid-01;
  border-style: none;
  border-radius: 5px;
  color: $gray-invalid-02;
  font-family: "SFProText-Regular";
  font-size: 24px;
  line-height: 24px;
  margin-top: 16px;
  cursor: not-allowed;

  @include for-tablet-portrait {
    width: 180px;
    height: 54px;
    font-size: 20px;
  }
}

.contact-form.success .contact-form__container .submit-button {
  background-color: $blue-dark;
  color: $white-03;
  cursor: pointer;
  transition: 0.3s;
}

.contact-form.success .contact-form__container .submit-button:hover {
  @include buttonHover;
}