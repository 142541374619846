@import '../../sassStyles/variables';
@import '../../sassStyles/mixins';

.slide-menu {
  width: 100vw;
  height: 250px;
  position: absolute;
  top: $nav-height;
  left: 0;
  background-color: $blue-regular;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  @include boxShaddow;
  
  .menu {
    width: 100%;
    height: 100%;

    &__links {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-content: center;
    }

    &__link {
      width: 90%;
      height: 50px;
      font-size: 20px;
      padding: 32px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .line {
    border-bottom: solid 1px $blue-regular;
    margin: 0 auto;
  }
}