@import '../../sassStyles/variables';
@import '../../sassStyles/mixins';

.contact {
  width: 100%;
  height: 956px;
  position: relative;
  margin: 0;

  @include for-laptop {
    height: 1206px;
  }
}